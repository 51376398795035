import React, { useEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'
import {
  WppButton,
  WppIconAdd,
  WppModal,
  WppIconClose,
  WppLabel,
  WppSelect,
  WppListItem,
  WppTypography
} from '@wppopen/components-library-react'
import { useDispatch, useSelector } from 'react-redux'

import DataGrid from 'components/dataGrid/DataGrid'
import Input from 'components/input/Input'
import TextArea from 'components/textArea/TextArea'
import { ValidationError } from 'containers/dmModule/inventoryList/interface'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'
import { AGENCY_NETWORK_GRID_HEADER_DETAILS } from 'helper/constants'
import serviceURL from 'helper/serviceURL'
import { validate } from 'helper/validate'
import useAxiosInterceptors from 'hooks/useAxiosInterceptors'
import { RootState } from 'redux/rootReducer'

import styles from './AgenciesNetwork.module.scss'

const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/agency`
let emailError = ''

interface DropDownData {
  name: string;
   code: string; 
   id: number
}

const INITIAL_DATA = {
  name: '',
  id: 0,
  code: ''
}

function AgenciesNetwork() {
  const isTriggerReload = useSelector((state: RootState) => state?.triggerReload?.data)
  const dispatch = useDispatch<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [agencyName, setAgencyName] = useState({ name: '', id: 0 })
  const [address, setAddress] = useState('')
  const [fullLegalName, setFullLegalName] = useState('')
  const [dpoEmailAddress, setDpoEmailAddress] = useState('')
  const [networkData, setNetworkData] = useState<DropDownData[]>([])
  const [marketData, setMarketData] = useState<DropDownData[]>([])
  const [regionData, setRegionData] = useState<DropDownData[]>([])
  const [selectedNetworkData, setSelectedNetworkData] = useState<DropDownData>(INITIAL_DATA)
  const [selectedRegion, setSelectedRegion] = useState<DropDownData>(INITIAL_DATA)
  const [selectedCountry, setSelectedCountry] = useState<DropDownData>(INITIAL_DATA)
  const [error, setError] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const { axiosInstance } = useAxiosInterceptors()
  const handleCloseModal = () => {
    setAgencyName({ name: '', id: 0 })
    setSelectedRegion(INITIAL_DATA)
    setSelectedCountry(INITIAL_DATA)
    setSelectedNetworkData(INITIAL_DATA)
    setAddress('')
    setFullLegalName('')
    setDpoEmailAddress('')
    setIsModalOpen(false)
    setEditMode(false)
    setError('')
    setValidationErrors([])
    emailError = ''
  }

  const onRowClicked = (data: {
    name: string
    id: number
    organization: { name: string; id: number; code: string }
    market: {
      name: string
      id: number
      code: string
      region: {
        name: string
        id: number
        code: string
      }
    }
    fullLegalName: string
    address: string
    dpoEmailAddress: string
  }) => {
    setAgencyName({ name: data?.name, id: data?.id })
    setSelectedRegion(data?.market?.region)
    setSelectedCountry(data?.market)
    setSelectedNetworkData(data?.organization)
    setAddress(data?.address)
    setFullLegalName(data?.fullLegalName)
    setDpoEmailAddress(data?.dpoEmailAddress)
    setIsModalOpen(true)
    setEditMode(true)
  }

  useEffect(() => {
    fetchRegionData()
    fetchMarketData()
    fetchNetworkData()
  }, [])

  useEffect(() => {
    if (selectedRegion?.name) {
      fetchMarketData()
    }
  }, [selectedRegion?.id])

  const fetchNetworkData = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations'
    axiosInstance
      .get(apiUrl)
      .then(res => {
        setNetworkData(res?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchRegionData = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/regions'
    axiosInstance
      .get(apiUrl)
      .then(res => {
        setRegionData(res?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchMarketData = () => {
    const apiUrl = serviceURL.pgpBaseAPI + `/api/organizations/regions/${selectedRegion?.id}/markets`
    axiosInstance
      .get(apiUrl)
      .then(res => {
        setMarketData(res?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const addMapping = (isEdit: boolean) => {
    let validateFields = {
      name: agencyName?.name,
      regionId: selectedRegion?.name,
      marketId: selectedCountry?.name,
      networkId: selectedNetworkData?.name
    }
    const validationList = validate(validateFields)
    setValidationErrors(validationList)
    if (dpoEmailAddress) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(dpoEmailAddress)) {
        emailError = 'Enter a valid email.'
      } else {
        emailError = ''
      }
    }
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (found || !!emailError) return
    const apiUrl = serviceURL.pgpBaseAPI + '/api/agency'
    setIsLoading(true)

    const payload = {
      name: agencyName?.name,
      ...(isEdit ? { id: agencyName?.id } : {}),
      organization: {
        id: selectedNetworkData?.id
      },
      market: {
        id: selectedCountry?.id
      },
      address,
      fullLegalName,
      dpoEmailAddress
    }
    const method = isEdit ? 'put' : 'post'
    axiosInstance[method](apiUrl, payload)
      .then(() => {
        setIsLoading(false)
        setIsModalOpen(false)
        setEditMode(false)
        setAgencyName({ name: '', id: 0 })
        setSelectedRegion(INITIAL_DATA)
        setSelectedCountry(INITIAL_DATA)
        setSelectedNetworkData(INITIAL_DATA)
        setAddress('')
        setFullLegalName('')
        setDpoEmailAddress('')
        setError('')
        dispatch(setTriggerReload(true))
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setError(error?.response?.data?.message)
      })
  }

  const updateValidationList = (fieldName: string) => {
    return validationErrors?.filter((item: any) => item.name !== fieldName)
  }

  return (
    <Container maxWidth="xl" className={styles.vContainer}>
      <Grid
        container
        spacing={{ xs: 2, md: 3, sm: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        className={styles.vendorContainer}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="flex-start"
          alignSelf="flex-start"
          className={styles.createVendorButton}
        >
          <WppButton
            className={styles.customBtnSecondary}
            variant="secondary"
            onClick={() => setIsModalOpen(true)}
            size="s"
            data-testid="mapping-create-button"
          >
            <WppIconAdd slot="icon-start" className={styles.customIcon} />
            Create Mapping
          </WppButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '3rem', marginBottom: '10rem' }}>
          <DataGrid
            apiEndpoint={baseApiUrl + '/search'}
            headerDetails={AGENCY_NETWORK_GRID_HEADER_DETAILS}
            apiMethod="POST"
            pageSize={25}
            isClearFilters={true}
            onRowClickedHandler={onRowClicked}
            isTriggerReload={isTriggerReload}
          />
        </Grid>
      </Grid>
      {isModalOpen && (
        <WppModal open={isModalOpen} size="m" onWppModalClose={handleCloseModal} className={styles.agencyModal}>
          <div slot="header" className={styles.mappingHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span data-testid="agency-heading">{editMode ? 'Edit Mapping' : 'Add Mapping'}</span>
            </h3>
            <WppIconClose color="black" onClick={handleCloseModal} className={styles.close} size="m" />
          </div>
          <div slot="body">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              rowSpacing={2}
              className={styles.agencyModalBody}
            >
              <Grid item xs={12} sm={4} md={4}>
                <WppLabel className={styles.label} config={{ text: 'Region' }} htmlFor="region" typography="s-strong" />
                <WppSelect
                  id="region"
                  placeholder="Select region"
                  value={selectedRegion}
                  withSearch={true}
                  size="s"
                  className={styles.respondDays}
                  onWppChange={event => {
                    setValidationErrors?.(updateValidationList('regionId'))
                    setSelectedCountry({ id: 0, name: '', code: '' })
                    setSelectedRegion(event?.target?.value)
                  }}
                  message={validationErrors?.find((item: any) => item.name === 'regionId')?.error || ''}
                  messageType={
                    validationErrors?.find((item: any) => item.name === 'regionId')?.error ? 'error' : undefined
                  }
                  data-testid="region"
                >
                  {regionData?.map((region: { name: string; id: number }) => (
                    <WppListItem key={region?.id} value={region}>
                      <p slot="label">{region?.name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Country' }}
                  htmlFor="country"
                  typography="s-strong"
                />
                <WppSelect
                  key={selectedRegion?.id || 'empty-region'}
                  id="country"
                  placeholder="Select country"
                  value={marketData?.find(market => market.id === selectedCountry?.id) || null}
                  withSearch={true}
                  size="s"
                  className={styles.respondDays}
                  onWppChange={event => {
                    setValidationErrors?.(updateValidationList('marketId'))
                    setSelectedCountry(event?.target?.value)
                  }}
                  disabled={selectedRegion?.name === ''}
                  message={validationErrors?.find((item: any) => item.name === 'marketId')?.error || ''}
                  messageType={
                    validationErrors?.find((item: any) => item.name === 'marketId')?.error ? 'error' : undefined
                  }
                   data-testid="country"
                >
                  {marketData?.map((market: { name: string; id: number }) => (
                    <WppListItem key={market?.id} value={market}>
                      <p slot="label">{market?.name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Network' }}
                  htmlFor="network"
                  typography="s-strong"
                />
                <WppSelect
                  id="network"
                  onWppChange={event => {
                    setValidationErrors?.(updateValidationList('networkId'))
                    setSelectedNetworkData(event.target.value)
                  }}
                  placeholder="Select network"
                  value={selectedNetworkData}
                  withSearch={true}
                  size="s"
                  className={styles.respondDays}
                  message={validationErrors?.find((item: any) => item.name === 'networkId')?.error || ''}
                  messageType={
                    validationErrors?.find((item: any) => item.name === 'networkId')?.error ? 'error' : undefined
                  }
                   data-testid="network"
                >
                  {networkData?.map(network => (
                    <WppListItem key={network?.name} value={network}>
                      <p slot="label">{network?.name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input
                  label="Agency Name"
                  id="agencyName"
                  placeholder="Enter agency name"
                  value={agencyName?.name}
                  onChange={e => {
                    setValidationErrors?.(updateValidationList('networkId'))
                    setAgencyName({ ...agencyName, name: e.target.value })
                  }}
                  maxLength={40}
                  message={validationErrors?.find((item: any) => item.name === 'name')?.error || ''}
                  data-testid="agency-name"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input
                  label="Full Legal Name (Optional)"
                  id="agencyName"
                  placeholder="Enter full legal name"
                  value={fullLegalName}
                  onChange={e => setFullLegalName(e.target.value)}
                  maxLength={40}
                  data-testid="legal-name"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input
                  label="DPO Email Address (Optional)"
                  id="agencyName"
                  placeholder="Enter DPO email address"
                  value={dpoEmailAddress}
                  onChange={e => setDpoEmailAddress(e.target.value)}
                  maxLength={40}
                  message={emailError}
                  data-testid="dpo-email"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Office Address (Optional)' }}
                  htmlFor="address"
                  typography="s-strong"
                />
                <TextArea
                  id="address"
                  placeholder="Enter office address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  maxLength={300}
                  data-testid="address"
                />
              </Grid>
              {error && (
                <WppTypography type="l-strong" className={styles.error}>
                  {error}
                </WppTypography>
              )}
            </Grid>
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={isLoading}
                onClick={() => addMapping(editMode)}
                data-testid="create-agencies"
              >
                {editMode ? 'Update' : 'Add'}
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={handleCloseModal}
                data-testid="cancel-btn"
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      )}
    </Container>
  )
}

export default AgenciesNetwork
